<template>
  <div>
    <b-overlay :show="loading">
      <b-card>
        <b-form @submit.prevent>
          <app-collapse>
            <app-collapse-item
              :is-visible="isVisible"
              title="Búsqueda"
            >
              <b-row>

                <b-col md="6">
                  <b-form-group>
                    <h5 class="font-weight-bold">
                      Fecha desde
                    </h5>
                    <div class="input-group">
                      <flat-pickr
                              v-model="filter.fechaDesde"
                              class="form-control"
                              placeholder="Click para seleccionar..."
                              :config="config"
                      />
                      <div class="input-group-append">
                        <button class="btn-clear" type="button" title="Limpiar" data-clear>
                          <feather-icon
                                  icon="XIcon"
                          />
                        </button>
                      </div>
                    </div>

                  </b-form-group>
                </b-col>
                <b-col md="6">
                  <b-form-group>
                    <h5 class="font-weight-bold">
                      Fecha Hasta
                    </h5>
                    <div class="input-group">
                      <flat-pickr
                              v-model="filter.fechaHasta"
                              class="form-control"
                              placeholder="Click para seleccionar..."
                              :config="config"
                      />
                      <div class="input-group-append">
                        <button class="btn-clear" type="button" title="Limpiar" data-clear>
                          <feather-icon
                                  icon="XIcon"
                          />
                        </button>
                      </div>
                    </div>
                  </b-form-group>
                </b-col>
                <b-col md="3">
                  <b-form-group>
                    <h5 class="font-weight-bold">
                      Inscripción
                    </h5>
                    <b-form-input
                      id="ficha"
                      v-model="filter.ficha"
                      placeholder="N° de Inscripción"
                    />
                  </b-form-group>
                </b-col>
                <b-col md="3">
                  <b-form-group>
                    <h5 class="font-weight-bold">
                      Orden de Compra
                    </h5>
                    <b-form-input
                      id="ordenCompra"
                      v-model="filter.ordenCompra"
                      placeholder="N° de Orden de Compra"
                    />
                  </b-form-group>
                </b-col>
                <b-col md="6">
                  <b-form-group>
                    <h5 class="font-weight-bold">
                      Cursos
                    </h5>
                    <div class="form-label-group">
                      <b-form-group>
                        <autocomplete-infinity
                          id="curso"
                          url="dash/filtro/curso"
                          placeholder="Seleccione Curso"
                          label="description"
                          items-per-page="10"
                          @item-selected="selectAplicaciones"
                        />

                      </b-form-group>
                    </div>
                  </b-form-group>
                </b-col>
                <b-col md="8">
                  <b-form-group
                  >
                    <h5 class="font-weight-bold">
                      Rut / Nombre / Apellidos
                    </h5>

                    <b-form-input
                        id="alumno"
                        v-model="filter.alumno"
                        placeholder="Escriba el dato del Alumno a buscar"
                    />
                  </b-form-group>
                </b-col>
                <b-col>
                  <div class="text-right mt-2">
                    <b-button
                      v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                      variant="primary"
                      type="submit"
                      @click="buscar"
                    >

                      <feather-icon
                        icon="SearchIcon"
                        class="mr-50"
                      />
                      <span class="align-middle">Buscar</span>
                    </b-button>
                  </div>
                </b-col>
              </b-row>
            </app-collapse-item>
          </app-collapse>
        </b-form>
      </b-card>
      <b-card>
        <h4 class="card-title">
          Reportes Generales
        </h4>
        <div v-if="showTable">
          <b-row class="pr-1 pl-1">
            <b-col
              sm="12"
              md="12"
              class="my-1 text-left"
            >
              <div class="d-flex justify-content-end">
                <b-button
                        v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                        v-b-modal.modal-tall
                        variant="primary"
                        @click="columnsState"

                >
                  <feather-icon
                          icon="EyeIcon"
                          class="mr-50"
                  />
                  <span class="align-middle">Mostrar Columnas</span>
                </b-button>

                <b-button
                  v-ripple.400="'rgba(40, 199, 111, 0.15)'"
                  variant="primary"
                  type="submit"
                  class="ml-1"
                  @click="exportar"
                >
                  <feather-icon
                    icon="DownloadIcon"
                    class="mr-50"
                  />
                  <span class="align-middle">Exportar</span>

                </b-button>
              </div>
            </b-col>
          </b-row>
          <b-row class="pr-1 pl-1 mt-1">
            <b-col
                    sm="4"
                    md="4"
                    class="my-1 text-right"
            >
              <div class="d-flex align-items-center mb-1 mb-md-0">
                <label>Mostrar</label>
                <b-form-select
                        id="perPageSelect"
                        v-model="perPage"
                        :options="pageOptions"
                        size="sm"
                        class="mx-50 col-md-3"
                        @change="onChangePerPage"
                />
                <label>filas por página</label>
              </div>
            </b-col>
            <b-col
                    cols="4"
                    class="mt-2"
            >
              <div v-if="totalRows > 0" class="d-flex justify-content-end pr-1">
                <span class="text-muted">  Mostrando del {{ from }} al {{ to }} de {{ totalRows }} filas</span>
              </div>
            </b-col>

            <b-col cols="4"  class="mt-1">
              <b-pagination
                      :total-rows="totalRows"
                      :value="page"
                      :per-page="perPage"
                      align="right"
                      aria-controls="my-table"
                      @change="onPageChange"
              />
            </b-col>
            <b-col
                    v-if="showTable"
                    cols="12"
            >
              <b-table
                      id="my-table"
                      ref="table"
                      striped
                      hover
                      small
                      :per-page="perPage"
                      :items="listado"
                      :fields="columnsVisible"
                      responsive
                      class="mb-0"
                      show-empty
              >

                <template #cell()="data">
                  {{ data.value ? data.value : '-' }}
                </template>
                <template #cell(fecha_inicio)="data">
                  <b-badge variant="light-success">
                    {{ data.item.fecha_inicio ? data.item.fecha_inicio : '-' }}
                  </b-badge>
                </template>
                <template #cell(fecha_cierre)="data">
                  <b-badge variant="light-success">
                    {{ data.item.fecha_cierre ? data.item.fecha_cierre : '-' }}
                  </b-badge>
                </template>
                <template #cell(estado_inscripcion)="data">
                  <b-badge
                          class="center"
                          :variant="estado_inscripcion(data.item.estado_inscripcion)"
                  >
                    {{ data.item.estado_inscripcion ? data.item.estado_inscripcion : '-' }}
                  </b-badge>
                </template>
                <template #cell(porciento_avance)="data">
                  <b-badge
                          class="center"
                          :variant="porciento_avance(data.item.porciento_avance)"
                  >
                    {{ data.item.porciento_avance ? data.item.porciento_avance : '-' }}
                  </b-badge>
                </template>
                <template #cell(data_auxiliar)="data">
                  <div class="text-center" v-if="data.item.data_auxiliar">
                    <b-button
                            id="tooltip-adicionales"
                            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                            variant="flat-primary"
                            class="btn-icon"
                            @click="showDatosAdicionales(data.item.data_auxiliar)"
                    >
                      <feather-icon icon="EyeIcon"
                      />
                    </b-button>
                    <b-tooltip target="tooltip-detalle" triggers="hover" variant="primary">
                      Ver datos adicionales
                    </b-tooltip>
                  </div>
                  <div v-else>-</div>
                </template>
                <template #cell(detalle_seguimiento)="data">
                  <div
                          v-if="data.item.detalle_seguimiento"
                          class="text-center"
                  >
                    <b-button
                            id="tooltip-detalle"
                            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                            variant="flat-primary"
                            class="btn-icon"
                            @click="showDetalleSeguimiento(data.item.detalle_seguimiento)"
                    >
                      <feather-icon
                              icon="EyeIcon"
                      />
                    </b-button>
                    <b-tooltip
                            target="tooltip-detalle"
                            triggers="hover"
                            variant="primary"
                    >
                      Ver detalle seguimiento
                    </b-tooltip>
                  </div>
                  <div class="text-center" v-else>
                    -
                  </div>
                </template>
                <template #empty="scope">
                  <div class="text-center">
                    No existen resultados
                  </div>
                </template>
              </b-table>

            </b-col>
          </b-row>
        </div>
      </b-card>
    </b-overlay>
    <modal-ds
      ref="modal_dtlle"
      :data-value="dataDetalle"
    />
    <modal-da ref="modal_adicionales" :dataValue="dataAdicionales" :actividades="actividades"></modal-da>
    <b-modal
      ref="modalExport"
      title="Información"
      ok-only
      ok-title="Cerrar"
    >
      <p class="my-4">
        <b>Procesando el reporte</b>! Recibirá un correo con el enlace para descargar el informe
      </p>
    </b-modal>
    <b-modal
            id="modal-tall"
            title="Modificar estado de las columnas y orden"
            scrollable
            size="lg"
            ok-variant="primary"
            ok-title="Cerrar"
            ok-only
    >
      <b-row class="pr-1 pl-1">
        <b-col md="12" class="mb-1 text-right">
          <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="primary"
                  @click="columnasPorDefecto"
          >
            <feather-icon
                    icon="CheckIcon"
                    class="mr-50"
            />
            Columnas por Defecto
          </b-button>
          <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="primary"
                  @click="checkAllColumns"
                  class="ml-1"
          >
            <feather-icon
                    icon="CheckSquareIcon"
                    class="mr-50"
            />
            Todas las columnas
          </b-button>
        </b-col>
        <b-col md="6">
          <h6 class="text-primary">Columnas Visibles</h6>
          <div v-bind="columnsInvisibles" class="d-none"></div>
          <div v-bind="columnsVisibles" class="d-none"></div>
          <draggable
                  :list="columnsVisible"
                  tag="ul"
                  group="people"
                  class="list-group list-group-flush cursor-move"
                  @change="cambio"
          >
            <b-list-group-item
                    v-for="listItem in columnsVisible"
                    :key="listItem.key"
                    tag="li"
            >
              <div class="d-flex">
                <div class="ml-25">
                  <b-card-text class="mb-0 font-weight-bold">
                    {{ listItem.label }}
                  </b-card-text>
                </div>
              </div>
            </b-list-group-item>
          </draggable>
        </b-col>
        <b-col md="6">
          <h6 class="text-primary">Columnas Ocultas</h6>

          <draggable
                  :list="columnsInvisible"
                  tag="ul"
                  group="people"
                  class="list-group list-group-flush cursor-move"
                  @change="cambio"
          >
            <b-list-group-item
                    v-for="listItem in columnsInvisible"
                    :key="listItem.key"
                    tag="li"
            >
              <div class="d-flex">
                <div class="ml-25">
                  <b-card-text class="mb-0 font-weight-bold">
                    {{ listItem.label }}
                  </b-card-text>
                </div>
              </div>
            </b-list-group-item>
          </draggable>
        </b-col>
      </b-row>
    </b-modal>
  </div>
</template>

<script>
import {
  BTable, BBadge, BRow, BCol, BFormGroup, BFormSelect, BPagination, BFormInput, BButton, BTooltip,
  BListGroupItem, BCardText} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import flatPickr from 'vue-flatpickr-component'
import AppCollapse from '@core/components/app-collapse/AppCollapse.vue'
import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue'
import ModalDetalleSeguimiento from '@/components/ModalDetalleSeguimiento'
import ModalDatosAdicionales from '@/components/ModalDatosAdicionales'
import draggable from 'vuedraggable'

export default {
  name: 'ReportesAmpliado',

  components: {
    BTable,
    BBadge,
    BRow,
    BCol,
    BFormGroup,
    BFormSelect,
    BPagination,
    BFormInput,
    BButton,
    flatPickr,
    AppCollapse,
    AppCollapseItem,
    BTooltip,
    BListGroupItem,
    BCardText,
    'modal-ds': ModalDetalleSeguimiento,
    'modal-da': ModalDatosAdicionales,
    draggable,
  },

  directives: {
    Ripple,
  },
  data() {
    return {
      showTable: false,
      perPage: 50,
      reportType: 1,
      currentPageV: 1,
      page: 1,
      list: [],
      columnsData: [],
      columnsVisible: [],
      columnsInvisible: [],
      dataDetalle: [],
      dataAdicionales: [],
      actividades: [],
      isVisible: true,
      load: false,
      filter: {
        fechaDesde: '',
        fechaHasta: '',
        type: 3,
        ficha: '',
        ordenCompra: '',
        aplicacion: '',
        holding: '',
        ejecutivo: '',
        alumno: '',
      },
      sortField: 'nombre',
      showColumn: false,
      showColumnText: 'Mostrar Columnas',
      pageOptions: [50, 100, 500, 1000],
      infoModal: {
        id: 'info-modal',
        title: '',
        content: '',
      },
    }
  },
  computed: {
    totalRows() {
      return this.$store.state.reportesAmpliado.rowsTotal
    },
    to() {
      return this.$store.state.reportesAmpliado.to
    },
    from() {
      return this.$store.state.reportesAmpliado.from
    },
    listado() {
      return this.$store.state.reportesAmpliado.list
    },
    currentPage() {
      return this.$store.state.reportesAmpliado.currentPage
    },
    loading() {
      return this.$store.state.reportesAmpliado.loading
    },
    columnsVisibles() {
      return this.columnsVisible = this.columnsVisible.length > 0 && this.load ? this.columnsVisible : this.columns.filter(h => h.display === true)
    },
    columnsInvisibles() {
      return this.columnsInvisible = this.columnsInvisible.length > 0 && this.load ? this.columnsInvisible : this.columns.filter(h => h.display === false)
    },
    columns() {
      const data = this.$store.state.reportesAmpliado.columns;
      this.columnsData = []
      for (const key in data) {
        const column = {
          key: data[key].field,
          label: data[key].title,
          display: (data[key].field == 'razon_social_empresa') || (data[key].field == 'nombre_holding') || (data[key].field == 'tiempo_conexion_plataforma') || (data[key].field == 'rut_empresa') ? false : data[key].visible,
          default: (data[key].field == 'razon_social_empresa') || (data[key].field == 'nombre_holding') || (data[key].field == 'tiempo_conexion_plataforma') || (data[key].field == 'rut_empresa') ? false : data[key].default,
          visible: (data[key].field == 'razon_social_empresa') || (data[key].field == 'nombre_holding') || (data[key].field == 'tiempo_conexion_plataforma') || (data[key].field == 'rut_empresa') ? false : true,
        }
        console.log('this.columnsData',this.columnsData)
        this.columnsData.push(column)
      }
      return this.columnsData
    },
    filteredHeaders() {
      return this.columns.filter(h => h.display === true)
    },
    config() {
      return {
        locale: require('flatpickr/dist/l10n/es.js').default['es'],
        dateFormat: "d-m-Y",
        wrap: true,
      }
    },

  },
  mounted() {
  },
  methods: {
    info(item, index, button) {
      this.infoModal.title = `Row index: ${index}`
      this.infoModal.content = JSON.stringify(item, null, 2)
      this.$root.$emit('bv::show::modal', this.infoModal.id, button)
    },
    buscar() {
      const h = this.$createElement
      const vNodesMsg = h(
        'p',
        { class: ['text-left', 'mb-0'] },
        [
          ' Uno de estos datos es obligatorio para realizar la busqueda: ',
          h('strong', 'Curso'),
          ', ',
          h('strong', 'Inscripción'),
          ' ú ',
          h('strong', 'Orden de Compra'),
        ],
      )
      if (this.filter.aplicacion == '' && this.filter.ficha == '' && this.filter.ordenCompra == '') {
        this.$bvToast.toast([vNodesMsg], {
          title: 'ALERTA!!',
          variant: 'warning',
          solid: true,
        })
      } else {
        this.getList()
        this.getColumns()
        this.showTable = true
      }
    },
    onPageChange(page) {
      this.page = page
      this.getList()
    },
    onChangePerPage() {
      this.page = 1
      this.getList()
    },
    getList() {
      const filterData = {
        type: 3,
        num_ficha: this.filter.ficha,
        num_orden_compra: this.filter.ordenCompra,
        fecha_desde: this.filter.fechaDesde,
        fecha_hasta: this.filter.fechaHasta,
        id_holding: this.filter.holding,
        id_aplicacion: this.filter.aplicacion,
        alumno: this.filter.alumno,
        sort_by: this.sortField,
        page: this.page,
        perPage: this.perPage,
      }
      this.$store.dispatch('reportesAmpliado/getList', filterData)
    },
    getColumns() {
      const filterData = {
        type: 3,
        num_ficha: this.filter.ficha,
        num_orden_compra: this.filter.ordenCompra,
        fecha_desde: this.filter.fechaDesde,
        fecha_hasta: this.filter.fechaHasta,
        id_holding: this.filter.holding,
        id_aplicacion: this.filter.aplicacion,
        sort_by: this.sortField,
        page: this.page,
        perPage: this.perPage,
      }
      this.$store.dispatch('reportesAmpliado/getColumns', filterData)
      this.load = false
    },
    showColumns() {
      this.showColumn = this.showColumn == true ? false : true;
      this.showColumnText = this.showColumn == true ? 'Ocultar Columnas' : 'Mostrar Columnas';
    },
    columnsDefault() {
      for (let key in this.columnsData) {
        this.columnsData[key].display = this.columnsData[key].default
      }
    },
    checkAllColumns() {
      this.load = true
      this.columnsData.forEach((item, index) => {
        item.display = true
      })
      this.columnsVisible = this.columns.filter(h => h.display === true)
      this.columnsInvisible = this.columns.filter(h => h.display === false)
    },
    porciento_avance(value) {
      const number = parseFloat(value)
      if (number < 50) {
        return 'light-danger'
      } if (number >= 50 && number < 100) {
        return 'light-warning'
      } if (number >= 100) {
        return 'light-success'
      }
    },
    estado_inscripcion(value) {

      if (value == 'Eliminado') {
        return 'light-danger'
      } if (value == 'Inscrito') {
        return 'light-success'
      }
    },
    exportar() {
      if (this.totalRows == 0) {
        this.$bvToast.toast('Debe realizar una búsqueda antes de exportar', {
          title: 'ALERTA!!',
          variant: 'danger',
          solid: true,
        })
      } else {
        this.getExportData()
      }
    },
    getExportData() {
      const fieldsV = []
      Object.entries(this.columnsData).forEach(entry => {
        const [key, value] = entry
        if (value.display) {
          fieldsV.push(value.key)
        }
      })
      const filterData = {
        type: 3,
        num_ficha: this.filter.ficha,
        num_orden_compra: this.filter.ordenCompra,
        fecha_desde: this.filter.fechaDesde,
        fecha_hasta: this.filter.fechaHasta,
        id_holding: this.filter.holding,
        id_aplicacion: this.filter.aplicacion,
        alumno: this.filter.alumno,
        sort_by: this.sortField,
        page: this.page,
        perPage: this.perPage,
        fields: fieldsV,
      }
      if (this.totalRows > 5000) {
        this.$refs.modalExport.show()
        this.$store.dispatch('reportesAmpliado/exportAllData', filterData)
      } else {
        this.$store.dispatch('reportesAmpliado/exportData', filterData)
      }
    },
    selectAplicaciones(aplicaciones) {
      this.filter.aplicacion = aplicaciones != null ? aplicaciones.id : null
    },
    showDetalleSeguimiento(data) {
      this.dataDetalle = Object.values(data)
      this.$refs.modal_dtlle.$children[0].show()
    },
    toCellName(slot) {
      return `cell(${slot})`
    },
    showDatosAdicionales(data){
      this.dataAdicionales = data
      if(this.dataAdicionales.actividades){
        this.actividades = this.dataAdicionales.actividades
      }else {
        this.actividades[0] = this.dataAdicionales
      }

      this.$refs.modal_adicionales.$children[0].show();
    },
    columnsState() {
      this.load = true
      this.columnsVisible = this.columnsVisible.filter(h => h.display === true)
      this.columnsInvisible = this.columnsInvisible.filter(h => h.display === false)
    },
    columnasPorDefecto() {
      this.load = true
      this.columnsData.forEach((item, index) => {
        // eslint-disable-next-line no-param-reassign
        item.display = item.default
      })
      this.columnsVisible = this.columns.filter(h => h.default === true)
      this.columnsInvisible = this.columns.filter(h => h.default === false)
    },
    cambio(column) {
      if (column.added) {
        // eslint-disable-next-line no-param-reassign
        column.added.element.display = !column.added.element.display
      }
    },
  },
}
</script>

<style scoped lang="scss">
  @import '@core/scss/vue/libs/vue-flatpicker.scss';
  .demo-inline-spacing.curso .custom-radio{
    margin-top: 0.5rem!important;

  }
  .btn-clear {
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #d8d6de;
    border-radius: 0px 3px 3px 0px!important;
    color: #6e6b7b;
  }
  [dir=ltr] .demo-inline-spacing.checkbox-inline > *{
    margin-right: 0rem;
  }
</style>
